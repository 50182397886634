
body {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 365px;
}

.body-fixed {
    @media only screen and (max-width: 991px) {
        overflow: hidden;
    }
}

.page-section-wrapper{
    
    width: 100%;

    .section-wrapper{
        width: 100%;
        position: relative;
        overflow: hidden;
    }
}
.feature-section-wrapper {
    margin-top: -1px;

    .section-bg {

        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;

        background-repeat: repeat;
        background-size: contain;
        background-position: bottom;

        background-image: url('./assets/images/feature-section/green.webp');

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background-image: url('./assets/images/feature-section/green@2x.webp');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (min--moz-device-pixel-ratio: 3),
        only screen and (-o-min-device-pixel-ratio: 3/1),
        only screen and (min-device-pixel-ratio: 3),
        only screen and (min-resolution: 288dpi),
        only screen and (min-resolution: 3dppx) {
            background-image: url('./assets/images/feature-section/green@3x.webp');
        }
    }

    .feature-box {

        position: relative;

        .section-header-box {
            margin-top: 50px;
        }

        .list-box {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;

            .list-item {
                width: 150px;
                height: 120px;
                display: flex;
                margin-left: 12px;
                margin-right: 12px;

                @media only screen and (max-width: 475px) {
                    width: 100px;
                    height: 80px;
                    display: flex;
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }
        }
    }

    .feature-description-box-mob-col-fix {
        @media only screen and (max-width: 767px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .feature-description-box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-top: 44px;
        padding-bottom: 28px;

        .title-box {
            z-index: 2;
            padding-top: 52px;
            position: relative;
            padding-left: 78px;
            padding-right: 78px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

            background-image: url('./assets/images/feature-description-section/feature_title_bg.webp');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (min--moz-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-device-pixel-ratio: 2),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
                background-image: url('./assets/images/feature-description-section/feature_title_bg@2x.webp');
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (min--moz-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-device-pixel-ratio: 3),
            only screen and (min-resolution: 288dpi),
            only screen and (min-resolution: 3dppx) {
                background-image: url('./assets/images/feature-description-section/feature_title_bg@3x.webp');
            }

            @media only screen and (max-width: 991px) {
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: -48px;

                background-size: cover;
                padding-top: 25px;

                background-image: url('./assets/images/feature-description-section/feature_title_mob_bg.webp');

                @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (min--moz-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-device-pixel-ratio: 2),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_title_mob_bg@2x.webp');
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (min--moz-device-pixel-ratio: 3),
                only screen and (-o-min-device-pixel-ratio: 3/1),
                only screen and (min-device-pixel-ratio: 3),
                only screen and (min-resolution: 288dpi),
                only screen and (min-resolution: 3dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_title_mob_bg@3x.webp');
                }
            }

            .title {
                .text {
                    margin-bottom: 10px;
                    font-family: "Chelsy", sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 55px;
                    line-height: 52px;
                    text-align: center;
                    text-transform: uppercase;

                    color: #FFE2C0;

                    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.45);

                    @media only screen and (max-width: 767px) {
                        font-size: 48px;
                        line-height: 48px;
                        padding-bottom: 10px;
                    }
                }
            }

            @media only screen and (max-width: 767px) {
                margin-bottom: -27px;
            }
        }

        .description-bg-1-wrapper {
            margin-top: -34px;
            position: relative;

            padding: 20px;
            background: #503220;
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.4);

            .description-bg-top-fragment {
                width: 100%;
                height: 55px;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 1;
                margin-top: -25px;

                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;

                background-image: url('./assets/images/feature-description-section/feature_bg_border_top.webp');

                @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (min--moz-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-device-pixel-ratio: 2),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_bg_border_top@2x.webp');
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (min--moz-device-pixel-ratio: 3),
                only screen and (-o-min-device-pixel-ratio: 3/1),
                only screen and (min-device-pixel-ratio: 3),
                only screen and (min-resolution: 288dpi),
                only screen and (min-resolution: 3dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_bg_border_top@3x.webp');
                }

                @media only screen and (max-width: 991px) {
                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 0;
                    margin-bottom: -12px;

                    height: 92px;
                    background-size: cover;
                    padding-top: -45px;

                    background-image: url('./assets/images/feature-description-section/feature_bg_border_top_mob.webp');

                    @media only screen and (-webkit-min-device-pixel-ratio: 2),
                    only screen and (min--moz-device-pixel-ratio: 2),
                    only screen and (-o-min-device-pixel-ratio: 2/1),
                    only screen and (min-device-pixel-ratio: 2),
                    only screen and (min-resolution: 192dpi),
                    only screen and (min-resolution: 2dppx) {
                        background-image: url('./assets/images/feature-description-section/feature_bg_border_top_mob@2x.webp');
                    }

                    @media only screen and (-webkit-min-device-pixel-ratio: 3),
                    only screen and (min--moz-device-pixel-ratio: 3),
                    only screen and (-o-min-device-pixel-ratio: 3/1),
                    only screen and (min-device-pixel-ratio: 3),
                    only screen and (min-resolution: 288dpi),
                    only screen and (min-resolution: 3dppx) {
                        background-image: url('./assets/images/feature-description-section/feature_bg_border_top_mob@3x.webp');
                    }

                    display: none;
                }
            }

            .description-bg-left-fragment {
                width: 12px;
                height: calc(100% - 10px);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                bottom: 0;

                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                background-image: url('./assets/images/feature-description-section/feature_bg_border.webp');

                @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (min--moz-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-device-pixel-ratio: 2),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_bg_border@2x.webp');
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (min--moz-device-pixel-ratio: 3),
                only screen and (-o-min-device-pixel-ratio: 3/1),
                only screen and (min-device-pixel-ratio: 3),
                only screen and (min-resolution: 288dpi),
                only screen and (min-resolution: 3dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_bg_border@3x.webp');
                }
            }

            .description-bg-right-fragment {
                width: 12px;
                height: calc(100% - 10px);
                position: absolute;
                top: 0;
                right: 0;
                z-index: 0;
                bottom: 0;

                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                background-image: url('./assets/images/feature-description-section/feature_bg_border.webp');

                @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (min--moz-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-device-pixel-ratio: 2),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_bg_border@2x.webp');
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (min--moz-device-pixel-ratio: 3),
                only screen and (-o-min-device-pixel-ratio: 3/1),
                only screen and (min-device-pixel-ratio: 3),
                only screen and (min-resolution: 288dpi),
                only screen and (min-resolution: 3dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_bg_border@3x.webp');
                }
            }

            .description-bg-bottom-fragment {
                width: 100%;
                height: 100px;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 1;
                bottom: -15px;

                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;

                background-image: url('./assets/images/feature-description-section/feature_bg_border_bottom.webp');

                @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (min--moz-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-device-pixel-ratio: 2),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_bg_border_bottom@2x.webp');
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (min--moz-device-pixel-ratio: 3),
                only screen and (-o-min-device-pixel-ratio: 3/1),
                only screen and (min-device-pixel-ratio: 3),
                only screen and (min-resolution: 288dpi),
                only screen and (min-resolution: 3dppx) {
                    background-image: url('./assets/images/feature-description-section/feature_bg_border_bottom@3x.webp');
                }

                @media only screen and (max-width: 991px) {

                    display: none;

                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 0;
                    margin-bottom: -12px;

                    background-size: cover;
                    height: 165px;

                    background-image: url('./assets/images/feature-description-section/feature_bg_border_bottom_mob.webp');

                    @media only screen and (-webkit-min-device-pixel-ratio: 2),
                    only screen and (min--moz-device-pixel-ratio: 2),
                    only screen and (-o-min-device-pixel-ratio: 2/1),
                    only screen and (min-device-pixel-ratio: 2),
                    only screen and (min-resolution: 192dpi),
                    only screen and (min-resolution: 2dppx) {
                        background-image: url('./assets/images/feature-description-section/feature_bg_border_bottom_mob@2x.webp');
                    }

                    @media only screen and (-webkit-min-device-pixel-ratio: 3),
                    only screen and (min--moz-device-pixel-ratio: 3),
                    only screen and (-o-min-device-pixel-ratio: 3/1),
                    only screen and (min-device-pixel-ratio: 3),
                    only screen and (min-resolution: 288dpi),
                    only screen and (min-resolution: 3dppx) {
                        background-image: url('./assets/images/feature-description-section/feature_bg_border_bottom_mob@3x.webp');
                    }
                }
            }

            .description-box-wrapper {
                margin: 4px;
                background: linear-gradient(180deg, #A67D5F 59.17%, #C59572 100%);


                position: relative;


                .description-box {
                    display: flex;
                    position: relative;
                    flex-direction: column;

                    margin-top: 40px;
                    margin-bottom: 40px;
                    //margin-top: -78px;
                    //z-index: -1;

                    //height: 650px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    background-image: url('./assets/images/feature-description-section/feature_bg.webp');

                    @media only screen and (-webkit-min-device-pixel-ratio: 2),
                    only screen and (min--moz-device-pixel-ratio: 2),
                    only screen and (-o-min-device-pixel-ratio: 2/1),
                    only screen and (min-device-pixel-ratio: 2),
                    only screen and (min-resolution: 192dpi),
                    only screen and (min-resolution: 2dppx) {
                        background-image: url('./assets/images/feature-description-section/feature_bg@2x.webp');
                    }

                    @media only screen and (-webkit-min-device-pixel-ratio: 3),
                    only screen and (min--moz-device-pixel-ratio: 3),
                    only screen and (-o-min-device-pixel-ratio: 3/1),
                    only screen and (min-device-pixel-ratio: 3),
                    only screen and (min-resolution: 288dpi),
                    only screen and (min-resolution: 3dppx) {
                        background-image: url('./assets/images/feature-description-section/feature_bg@3x.webp');
                    }

                    .description-border-wrapper {
                        border: 4px solid #F3B88C;
                        margin: 20px;

                        .description {
                            margin-top: 36px;
                            margin-bottom: 40px;
                            margin-left: auto;
                            margin-right: auto;
                            padding-left: 10px;
                            padding-right: 10px;

                            .items {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: flex-start;
                                flex-wrap: wrap;

                                .item {
                                    display: flex;

                                    flex-direction: column;
                                    justify-content: space-between;
                                    align-items: stretch;

                                    margin-left: 20px;
                                    margin-right: 20px;

                                    .icon-box {
                                        width: 160px;
                                        height: 160px;
                                        margin: auto;

                                        .icon {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .name {
                                        font-family: "Chelsy", sans-serif;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 32px;
                                        line-height: 32px;
                                        text-align: center;
                                        color: #24140A;

                                        margin-bottom: 8px;
                                    }

                                    .body {
                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 22px;
                                        color: #24140A;
                                    }


                                    @media only screen and (min-width: 1199px) {
                                        max-width: 250px;
                                        flex-wrap: nowrap;
                                    }


                                    @media only screen and (max-width: 1400px) {
                                        max-width: 200px;
                                    }

                                    @media only screen and (max-width: 1199px) {
                                        max-width: 160px;
                                    }

                                    @media only screen and (max-width: 991px) {
                                        max-width: 245px;
                                    }

                                    @media only screen and (max-width: 767px) {
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                    }


                }

                .description-papper-fragment {

                    width: 150px;
                    height: 50px;
                    background-position: bottom;
                    background-size: contain;

                    position: absolute;
                    right: -3px;
                    bottom: 2px;

                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;

                    background-image: url('./assets/images/feature-description-section/fragment.webp');

                    @media only screen and (-webkit-min-device-pixel-ratio: 2),
                    only screen and (min--moz-device-pixel-ratio: 2),
                    only screen and (-o-min-device-pixel-ratio: 2/1),
                    only screen and (min-device-pixel-ratio: 2),
                    only screen and (min-resolution: 192dpi),
                    only screen and (min-resolution: 2dppx) {
                        background-image: url('./assets/images/feature-description-section/fragment@2x.webp');
                    }

                    @media only screen and (-webkit-min-device-pixel-ratio: 3),
                    only screen and (min--moz-device-pixel-ratio: 3),
                    only screen and (-o-min-device-pixel-ratio: 3/1),
                    only screen and (min-device-pixel-ratio: 3),
                    only screen and (min-resolution: 288dpi),
                    only screen and (min-resolution: 3dppx) {
                        background-image: url('./assets/images/feature-description-section/fragment@3x.webp');
                    }
                }


            }

            @media only screen and (max-width: 991px) {
                padding-bottom: 0;
                margin-bottom: -52px;
            }
        }
    }
}
* {
  //font-family: "Chelsy", sans-serif;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: left;
  color: #ffe2c0;

  @media only screen and (max-width: 991px) {
    font-size: 16px;
    line-height: 16px;
  }
}

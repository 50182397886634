.wood-bg-fragment {
    width: 100%;
    height: 50px;

    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    background-image: url('/assets/images/fragments/wood_bg_fragment.webp');

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        background-image: url('/assets/images/fragments/wood_bg_fragment@2x.webp');
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 3),
    only screen and (min--moz-device-pixel-ratio: 3),
    only screen and (-o-min-device-pixel-ratio: 3/1),
    only screen and (min-device-pixel-ratio: 3),
    only screen and (min-resolution: 288dpi),
    only screen and (min-resolution: 3dppx) {
        background-image: url('/assets/images/fragments/wood_bg_fragment@3x.webp');
    }

    .screw {
        position: absolute;


        width: 18px;
        height: 18px;

        top: 0;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &.left {
            left: 14px;
            background-image: url('/assets/images/fragments/screw-left.svg');
        }

        &.right {
            right: 14px;
            background-image: url('/assets/images/fragments/screw-right.svg');
        }
    }

}
.road-map-section-wrapper {

    .wood-bg-fragment-top {
        margin-top: -6px;
        margin-bottom: -14px;
    }

    .section-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        background-image: url('./assets/images/road-map-section/road-map-bg.webp');

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background-image: url('./assets/images/road-map-section/road-map-bg@2x.webp');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (min--moz-device-pixel-ratio: 3),
        only screen and (-o-min-device-pixel-ratio: 3/1),
        only screen and (min-device-pixel-ratio: 3),
        only screen and (min-resolution: 288dpi),
        only screen and (min-resolution: 3dppx) {
            background-image: url('./assets/images/road-map-section/road-map-bg@3x.webp');
        }
    }

    .road-map-section {
        width: 100%;
        height: 664px;
        overflow: auto;

        .road-map-box {

            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom;

            background-image: url('./assets/images/road-map-section/road-map-time-line.svg');
            height: 90%;

            min-width: 1200px;

            scroll-margin-right: 75px;
            
            ::-webkit-scrollbar {
                width: 2px;
                height: 2px;
            }
    
            ::-webkit-scrollbar-button {
                width: 2px;
                height: 2px;
            }

            .road-map-item {

                .description-box {
                    padding: 16px 24px;
                    gap: 8px;

                    background: rgba(36, 20, 10, 0.75);
                    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.6);
                    backdrop-filter: blur(20px);
                    /* Note: backdrop-filter has minimal browser support */

                    border-radius: 24px;

                    .title {
                        margin-bottom: 0;

                        text-align: center;

                        font-family: 'Chelsy';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 32px;

                        text-transform: uppercase;

                        color: #FFFFFF;
                    }

                    .description {
                        text-align: center;

                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 0;


                        color: #FFE2C0;
                    }
                }

                .point-box {
                    margin-top: 40px;
                    width: 100%;
                    height: 138px;

                    .point {
                        width: 100%;
                        height: 100%;

                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;

                        background-image: url('./assets/images/road-map-section/point.svg');
                    }
                }

                &.here-now {
                    .point-box {
                        height: 240px;
                        margin-top: 0;

                        .point {
                            background-image: url('./assets/images/road-map-section/point_active.svg');
                        }
                    }
                }

                &.disabled {
                    .point-box {
                        .point {
                            background-image: url('./assets/images/road-map-section/point_disabled.svg');
                        }
                    }
                }
            }


        }
    }
}
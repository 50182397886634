.footer-section-wrapper {
    background-color: #000000;

    .footer-section {

        margin-top: 64px;
        margin-bottom: 58px;

        .logo-box {
            height: 48px;

            .logo {
                width: 166px;
                height: 78px;
                display: flex;
                margin-top: -15px;
            }

            @media only screen and (max-width: 991px) {
                margin-bottom: 50px;

                .logo {
                    display: flex;
                    margin: auto;
                }
            }
        }

        .menu-box {
            display: flex;
            justify-content: center;


            .menu-btn {
                position: relative;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;

                text-transform: uppercase;

                color: #FFFFFF;

                text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.45);

                text-decoration: none;

                padding: 10px 16px;
                border: 1px solid transparent;

                .coming-soon-icon {
                    color: #C2133D;
                    background-color: #FFFFFF;
                    border-radius: 10px;
                    position: absolute;
                    top: -8px;
                    font-weight: 700;
                    font-size: 8px;
                    line-height: 18px;
                    letter-spacing: 0.02em;
                    text-transform: uppercase;
                    text-shadow: none;
                    height: 16px;
                    padding: 0px 8px;
                    right: 6px;
                }

                &:hover {
                    background: rgba(63, 37, 21, 0.75);
                    border: 1px solid #A56B43;
                    border-radius: 8px;
                }

                &:active {
                    background: rgba(36, 20, 10, 0.75);
                    border: 1px solid #A56B43;
                    border-radius: 8px;
                }
            }

            @media only screen and (max-width: 991px) {
                justify-content: space-between;
                width: 100%;
                margin-bottom: 23px;
                flex-wrap: wrap;

                flex-direction: column;

                .menu-btn {
                    margin-bottom: 23px;
                    margin-right: 0;
                    text-align: center;

                    .coming-soon-icon {
                        margin-right: 33%;
                    }
                }
            }
        }

        .discord-box {
            display: flex;
            //height: 48px;
            justify-content: end;

            .discord-btn {
                padding-top: 6px;
                padding-bottom: 6px;
                padding-left: 24px;
                padding-right: 24px;
                background: rgba(63, 37, 21, 0.75);
                border: 1px solid #A56B43;
                border-radius: 8px;
                display: flex;

                text-decoration: none;

                .icon {
                    display: flex;
                    align-items: center;

                    .discord-icon {
                        width: 31px;
                        height: 24px;
                        background-image: url('./assets/images/discord-icon.svg');
                    }
                }

                .text,
                span {
                    padding-left: 10px;
                    display: flex;
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    flex-wrap: nowrap;

                    .text-bold {
                        font-weight: 700;
                    }
                }

                &:hover {
                    background: #3F2515;
                    border: 1px solid #A56B43;
                }

                &:active {
                    background: rgba(36, 20, 10, 0.75);
                    border: 1px solid #A56B43;
                }
            }

            @media only screen and (max-width: 991px) {
                .discord-btn {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .divider-box {
            width: 100%;
            height: 100%;
            margin-top: 40px;
            margin-bottom: 40px;

            .divider {
                background: #71432F;
                height: 1px;
            }
        }

        .link-box {

            .link-list {
                display: flex;
                flex-direction: row;
                text-align: center;
                flex-wrap: wrap;

                a {
                    color: #FFE2C0;
                    text-decoration: none;
                    text-align: center;
                    font-size: 14px;

                    &:hover{
                        color: white;
                    }
                }

                .divider {
                    margin-left: 16px;
                    margin-right: 16px;
                    background-color: #FFE2C0;
                    width: 4px;
                    height: 4px;
                    border-radius: 4px;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .all-rights-reserved {}

                @media only screen and (max-width: 991px) {
                    .all-rights-reserved {
                        width: 100%;
                    }

                    .all-rights-reserved-divider {
                        display: none;
                    }

                    a {
                        width: 45%;
                       
                    }

                    .left-text {
                        margin-left: auto;
                        margin-right: auto;
                        text-align: end;
                    }

                    .right-text {
                        margin-left: auto;
                        margin-right: auto;
                        text-align: left;
                    }
                }
            }

            .social-link-list {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .social-link {
                    width: 24px;
                    height: 24px;
                    display: flex;

                    cursor: pointer;

                    &.facebook {
                        background-image: url('./assets/images/footer-section/facebook.svg');
                    }

                    &.medium {
                        background-image: url('./assets/images/footer-section/medium.svg');
                    }

                    &.reddit {
                        background-image: url('./assets/images/footer-section/reddit.svg');
                    }

                    &.twitter {
                        background-image: url('./assets/images/footer-section/twitter.svg');
                    }

                    &.youtube {
                        background-image: url('./assets/images/footer-section/youtube.svg');
                    }

                    &:hover{
                        filter: grayscale(1);
                    }
                }
            }

            @media only screen and (max-width: 991px) {
                flex-direction: column-reverse;

                .social-link-list {
                    margin-bottom: 34px;
                }
            }
        }


    }
}
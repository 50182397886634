.creatures-and-abilities-section-wrapper {

    .wood-bg-fragment-top {
        margin-top: -6px;
        margin-bottom: -14px;
    }

    .section-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        background-image: url('./assets/images/creatures-and-abilities-section/bg.webp');

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background-image: url('./assets/images/creatures-and-abilities-section/bg@2x.webp');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (min--moz-device-pixel-ratio: 3),
        only screen and (-o-min-device-pixel-ratio: 3/1),
        only screen and (min-device-pixel-ratio: 3),
        only screen and (min-resolution: 288dpi),
        only screen and (min-resolution: 3dppx) {
            background-image: url('./assets/images/creatures-and-abilities-section/bg@3x.webp');
        }
    }

    .creatures-and-abilities-box {
        .section-header-box {
            margin-top: calc(38px + 38px);
        }

        .creature-selector-box-wrapper {
            display: flex;
            flex-direction: row;

            .creature-selector-box {
                width: 100%;
                max-width: 672px;

                background: #3F2515;
                border-radius: 30px;

                margin-left: auto;
                margin-right: auto;

                .selector-box {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 100%;
                    justify-content: center;
                    justify-content: space-evenly;

                    .selector-item {
                        width: 160px;
                        height: 56px;

                        padding: 16px 24px;
                        margin: 4px;

                        border-radius: 24px;

                        font-family: "Chelsy", sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 32px;

                        color: #FFE2C0;
                        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.45);

                        text-align: center;
                        text-transform: uppercase;

                        display: flex;
                        justify-content: center;

                        cursor: pointer;

                        span {
                            font-family: "Chelsy", sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 32px;
                            line-height: 25px;
                        }

                        &:hover {
                            background: linear-gradient(180deg, rgba(255, 175, 126, 0.7) 0%, rgba(138, 83, 50, 0.35) 93.83%), url('./assets/images/header-section/panel.webp');
                            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.4), inset 0px 2px 0px rgba(255, 202, 139, 0.45), inset 0px -4px 0px #542E18;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: auto;
                        }

                        &:active {
                            background: linear-gradient(0deg, rgba(84, 46, 24, 0.4), rgba(84, 46, 24, 0.4)), url('./assets/images/header-section/panel.webp');
                            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.4), inset 0px 2px 0px rgba(255, 202, 139, 0.25), inset 0px -4px 0px #542E18;

                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: auto;
                        }

                        &.selected {

                            background: linear-gradient(180deg, rgba(255, 175, 126, 0.55) 0%, rgba(138, 83, 50, 0) 93.83%), url('./assets/images/header-section/panel.webp');
                            box-shadow: inset 0px 2px 0px rgba(255, 202, 139, 0.45), inset 0px -4px 0px #542E18;

                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: auto;
                        }
                    }
                }
            }
        }

        .creature-slider-box-wrapper {
            margin-top: 60px;
            margin-bottom: 92px;

            .creature-slider-box {
                .slide-body-box-wrapper {
                    background: #3F2515;
                    border: 16px solid #24140A;
                    border-radius: 40px;

                    .slide-body-box {
                        border: 6px solid #71432F;
                        border-radius: 40px;

                        margin: 15px;
                        padding: 56px;

                        .slide-title {

                            display: flex;
                            flex-direction: row;

                            .icon-box {

                                display: flex;

                                .icon {
                                    width: 80px;
                                    width: 100%;
                                }
                            }

                            .title-box-wrapper {
                                margin-left: 16px;
                                width: 100%;

                                .badge-box {
                                    width: 100%;

                                    .badge {
                                        width: auto;
                                        padding: 4px 16px;

                                        background: #FCE7CE;
                                        border-radius: 15px;

                                        font-family: 'Open Sans';
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 12px;
                                        line-height: 16px;
                                        text-align: center;
                                        text-transform: uppercase;

                                        color: #24140A;
                                    }
                                }

                                .title-box {
                                    .title {
                                        margin-top: 8px;

                                        font-family: "Chelsy", sans-serif;
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 48px;
                                        line-height: 48px;

                                        align-items: center;
                                        text-transform: uppercase;

                                        color: #FFFFFF;
                                    }
                                }
                            }
                        }

                        .slide-body {
                            //margin-top: 40px;
                            margin-top: 20px;
                            p {
                                //font-family: "Chelsy", sans-serif;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 25px;

                                color: #FFE2C0;
                            }
                        }
                    }
                }

                .image-box-wrapper {
                    width: 100%;
                    height: 100%;

                    .image-box {
                        width: 100%;
                        height: 100%;
                        min-height: 360px;

                        .image {
                            width: 100%;
                            height: 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }

                @media only screen and (max-width: 991px) {
                    .row {
                        flex-direction: column-reverse;
                        display: flex;

                        .image {
                            min-height: 360px;
                        }

                        .slide-body-box {
                            padding: 20px;
                        }

                    }
                }
            }
        }

    }

    .how-cards-work-box-wrapper {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        background-image: url('./assets/images/how-cards-work/bg.webp');

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background-image: url('./assets/images/how-cards-work/bg@2x.webp');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (min--moz-device-pixel-ratio: 3),
        only screen and (-o-min-device-pixel-ratio: 3/1),
        only screen and (min-device-pixel-ratio: 3),
        only screen and (min-resolution: 288dpi),
        only screen and (min-resolution: 3dppx) {
            background-image: url('./assets/images/how-cards-work/bg@3x.webp');
        }

        .how-cards-work-bg-top-fragment {
            width: 100%;
            height: 17px;
            margin-top: -8px;

            position: absolute;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            background-image: url('./assets/images/how-cards-work/bg-fragment.webp');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (min--moz-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-device-pixel-ratio: 2),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
                background-image: url('./assets/images/how-cards-work/bg-fragment@2x.webp');
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (min--moz-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-device-pixel-ratio: 3),
            only screen and (min-resolution: 288dpi),
            only screen and (min-resolution: 3dppx) {
                background-image: url('./assets/images/how-cards-work/bg-fragment@3x.webp');
            }
        }

        .how-cards-work-box {

            .how-cards-work-card-box{
                .section-header-box {
                    .section-header {
                        text-align: left;
                    }
                }
    
                .image-box {
                    width: 100%;
                    height: 100%;
                    min-height: 360px;
    
                    .image {
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-position: top;
                        background-size: cover;
                    }
                }
    
                .description-box {
                    
                    margin-top: 87px;
    
                    .name {
                        margin-top: 32px;
    
                        font-family: "Chelsy", sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 32px;
    
                        display: flex;
                        align-items: center;
                        text-transform: uppercase;
    
                        color: #FFFFFF;
                    }
    
                    .description {
                        margin-top: 16px;
                        margin-bottom: 70px;
                        //text-align: justify;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 25px;
    
                        color: #FFE2C0;
                    }

                    @media only screen and (max-width: 991px) {
                        .description {
                            p {
                                line-height: 22px;
                            }
                        }
                    }
                }
    
    
                @media only screen and (max-width: 1200px) {
                    .image-box {
                        margin-top: 30px;
                        .image {
                            background-size: contain;
                            background-position: center;
                        }
                    }

                    .description-box{
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}
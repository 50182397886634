.partnership-section-wrapper{
    background: #24140A;

    .wood-bg-fragment-top {
        margin-top: -6px;
        margin-bottom: -14px;
    }

    .partnership-section{
        .partnership-box{
            margin-top: 130px;
            margin-bottom: 80px;
            .list-box {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
    
                .list-item {
                    width: 150px;
                    height: 120px;
                    display: flex;
                    margin-left: 12px;
                    margin-right: 12px;
    
                    @media only screen and (max-width: 475px) {
                        width: 100px;
                        height: 80px;
                        display: flex;
                        margin-left: 4px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
    
}
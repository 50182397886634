.how-to-buy-or-mint-section-wrapper {

    .wood-bg-fragment-top {
        margin-top: -6px;
        margin-bottom: -14px;
    }

    .section-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        background: linear-gradient(0deg, rgba(63, 37, 21, 0.8), rgba(63, 37, 21, 0.8)), url('./assets/images/creatures-and-abilities-section/bg.webp');

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background: linear-gradient(0deg, rgba(63, 37, 21, 0.8), rgba(63, 37, 21, 0.8)), url('./assets/images/creatures-and-abilities-section/bg@2x.webp');
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (min--moz-device-pixel-ratio: 3),
        only screen and (-o-min-device-pixel-ratio: 3/1),
        only screen and (min-device-pixel-ratio: 3),
        only screen and (min-resolution: 288dpi),
        only screen and (min-resolution: 3dppx) {
            background: linear-gradient(0deg, rgba(63, 37, 21, 0.8), rgba(63, 37, 21, 0.8)), url('./assets/images/creatures-and-abilities-section/bg@3x.webp');
        }
    }

    .how-to-buy-or-mint-section {
        margin-top: 60px;
        margin-bottom: 100px;

        .section-header-box {
            margin-top: 62px;
            margin-bottom: 32px;
        }

        .image-box {
            width: 100%;
            height: 100%;
            min-height: 360px;

            .image {
                width: 100%;
                height: 100%;

                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

                background-image: url('./assets/images/how-to-buy-or-mint-section/group.webp');

                @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (min--moz-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-device-pixel-ratio: 2),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                    background-image: url('./assets/images/how-to-buy-or-mint-section/group@2x.webp');
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (min--moz-device-pixel-ratio: 3),
                only screen and (-o-min-device-pixel-ratio: 3/1),
                only screen and (min-device-pixel-ratio: 3),
                only screen and (min-resolution: 288dpi),
                only screen and (min-resolution: 3dppx) {
                    background-image: url('./assets/images/how-to-buy-or-mint-section/group@3x.webp');
                }
            }
        }

        .description-box {
            margin-top: 25px;

            .description {
                p {}
            }

            @media only screen and (max-width: 991px) {
                .description {
                    p {
                        line-height: 22px;
                    }
                }
            }
        }

        .btn-box {
            margin-top: 48px;

            .btn {

                padding: 24px 16px;

                span {
                    font-family: 'Chelsy';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 32px;
                    text-transform: uppercase;
                    color: #FFE2C0;
                    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
                }

                border: none;

                /*background: linear-gradient(180deg, rgba(255, 175, 126, 0.55) 0%, rgba(138, 83, 50, 0) 93.83%), url('/assets/images/header-section/panel.webp');
                box-shadow: inset 0px 2px 0px rgba(255, 202, 139, 0.45), inset 0px -4px 0px #542E18;
                filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.4));*/

                border-radius: 24px;
                margin-right: 24px;

                background: linear-gradient(180deg, rgba(255, 175, 126, 0.55) 0%, rgba(138, 83, 50, 0) 93.83%),
                url('./assets/images/header-section/panel.webp');
                box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.4),
                inset 0px 2px 0px rgba(255, 202, 139, 0.25),
                inset 0px -4px 0px #542E18;

                background-size: 1555px;
                background-repeat: no-repeat;
                background-position: center;

                &:hover {
                    background: linear-gradient(180deg, rgba(255, 175, 126, 0.7) 0%, rgba(138, 83, 50, 0.35) 93.83%), url('./assets/images/header-section/panel.webp');
                    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.4), inset 0px 2px 0px rgba(255, 202, 139, 0.45), inset 0px -4px 0px #542E18;
                    background-size: 1555px;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                &:active {
                    background: linear-gradient(0deg, rgba(84, 46, 24, 0.4), rgba(84, 46, 24, 0.4)), url('./assets/images/header-section/panel.webp');
                    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.4), inset 0px 2px 0px rgba(255, 202, 139, 0.25), inset 0px -4px 0px #542E18;

                    background-size: 1555px;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            @media only screen and (max-width: 991px) {
                display: flex;

                .btn {
                    width: 100%;
                }
            }
        }
    }
}
.header-section {
    position: fixed;
    display: contents;

    width: 100%;
    height: 100px;
    //height: 100%;
    display: flex;

    min-width: 365px;

    z-index: 100;

    .header-nav-box {

        position: relative;
        height: 100px;

        .header-nav-bg {


            height: 100%;
            position: absolute;
            z-index: -1;

            width: calc(100% + 24px);
            left: -12px;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            background-image: url('./assets/images/header-section/panel.webp');

            @media only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (min--moz-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (min-device-pixel-ratio: 2),
            only screen and (min-resolution: 192dpi),
            only screen and (min-resolution: 2dppx) {
                background-image: url('./assets/images/header-section/panel@2x.webp');
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (min--moz-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (min-device-pixel-ratio: 3),
            only screen and (min-resolution: 288dpi),
            only screen and (min-resolution: 3dppx) {
                background-image: url('./assets/images/header-section/panel@3x.webp');
            }




            @media only screen and (min-width: 991px) {
                width: calc(100% + 400px);
                left: -200px;
            }

            @media only screen and (max-width: 991px) {
                width: calc(100% + 400px);
                left: -200px;
            }

            @media only screen and (min-width: 1250px) {
                width: calc(100% + 200px);
                left: -100px;
            }

            @media only screen and (max-width: 475px) {
                background-image: url('./assets/images/header-section/panel_mob.webp');

                @media only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (min--moz-device-pixel-ratio: 2),
                only screen and (-o-min-device-pixel-ratio: 2/1),
                only screen and (min-device-pixel-ratio: 2),
                only screen and (min-resolution: 192dpi),
                only screen and (min-resolution: 2dppx) {
                    background-image: url('./assets/images/header-section/panel_mob@2x.webp');
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 3),
                only screen and (min--moz-device-pixel-ratio: 3),
                only screen and (-o-min-device-pixel-ratio: 3/1),
                only screen and (min-device-pixel-ratio: 3),
                only screen and (min-resolution: 288dpi),
                only screen and (min-resolution: 3dppx) {
                    background-image: url('./assets/images/header-section/panel_mob@3x.webp');
                }
            }

            @media only screen and (max-width: 475px) {
                background-size: contain;
                top: 0px;
                background-position: top;
            }
        }

        .header-nav {

            //margin-left: calc(55px + 6px + 16px);
            //margin-right: calc(55px + 6px + 16px);

            height: calc(100% - 15px);

            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo-box {
                height: 100%;
                display: flex;

                .logo {
                    width: 166px;
                    height: 60px;
                    display: flex;
                    margin-top: 6%;
                }
            }

            .menu-box {
                display: flex;
                //width: 45%;
                justify-content: space-between;

                .menu-btn {
                    position: relative;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;

                    text-transform: uppercase;

                    color: #FFFFFF;

                    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.45);

                    //margin-right: 34px;

                    text-decoration: none;

                    padding: 10px 16px;
                    border: 1px solid transparent;

                    .coming-soon-icon {
                        color: #C2133D;
                        background-color: #FFFFFF;
                        border-radius: 10px;
                        position: absolute;
                        top: -8px;
                        font-weight: 700;
                        font-size: 8px;
                        line-height: 18px;
                        letter-spacing: 0.02em;
                        text-transform: uppercase;
                        text-shadow: none;
                        height: 16px;
                        padding: 0px 8px;
                        right: 6px;
                    }

                    &:hover {
                        background: rgba(63, 37, 21, 0.75);
                        border: 1px solid #A56B43;
                        border-radius: 8px;
                    }

                    &:active {
                        background: rgba(36, 20, 10, 0.75);
                        border: 1px solid #A56B43;
                        border-radius: 8px;
                    }
                }
            }

            .discord-box {
                display: flex;
                //height: 48px;

                .discord-btn {
                    padding-top: 7px;
                    padding-bottom: 7px;
                    padding-left: 24px;
                    padding-right: 24px;
                    background: rgba(63, 37, 21, 0.75);
                    border: 1px solid #A56B43;
                    border-radius: 8px;
                    display: flex;

                    text-decoration: none;

                    .icon {
                        display: flex;
                        align-items: center;

                        .discord-icon {
                            width: 31px;
                            height: 24px;
                            background-image: url('./assets/images/discord-icon.svg');
                        }
                    }

                    .text,
                    span {
                        padding-left: 10px;
                        display: flex;
                        font-family: "Open Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        flex-wrap: nowrap;

                        .text-bold {
                            font-weight: 700;
                        }
                    }

                    &:hover {
                        background: #3F2515;
                        border: 1px solid #A56B43;
                    }

                    &:active {
                        background: rgba(36, 20, 10, 0.75);
                        border: 1px solid #A56B43;
                    }
                }
            }

            .menu-mob-box {

                display: none;

                .menu-mob-btn {
                    content: ' ';
                    width: 40px;
                    height: 40px;
                    margin-top: -8px;

                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;

                    &.open {
                        background-image: url('./assets/images/header-section/menu-mob-open-btn.svg');
                    }

                    &.close {
                        background-image: url('./assets/images/header-section/menu-mob-close-btn.svg');
                    }
                }
            }

            @media only screen and (max-width: 1400px) {
                /*margin-left: 0px;
                margin-right: 0px;*/
            }

            @media only screen and (max-width: 991px) {
                margin-left: 0px;
                margin-right: 0px;

                .menu-box,
                .discord-box {
                    display: none;
                }

                .menu-mob-box {
                    display: flex;
                }
            }

            @media only screen and (max-width: 475px) {
                height: calc(100% - 30px);
            }

        }
    }

    &.header-mob-view-section {

        .header-mob-view {
            top: 0;
            background: rgba(63, 37, 21, 0.95);
            width: 100%;
            height: 100%;
            min-height: 200vh;
            position: absolute;
            z-index: -2;
            display: none;

            .header-mob-box {
                width: 100%;

                .header-mob {
                    margin-top: 80px;

                    .menu-mob-box {
                        display: flex;
                        flex-direction: column;

                        .menu-mob-btn {
                            margin-top: 50px;

                            text-decoration: none;
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 28px;

                            text-transform: uppercase;

                            text-align: center;

                            color: #FFFFFF;

                            text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.45);

                            position: relative;

                            padding: 10px 16px;
                            border: 1px solid transparent;

                            .coming-soon-icon {
                                color: #C2133D;
                                background-color: #FFFFFF;
                                border-radius: 10px;
                                position: absolute;
                                top: -8px;
                                font-weight: 700;
                                font-size: 8px;
                                line-height: 18px;
                                letter-spacing: 0.02em;
                                text-transform: uppercase;
                                text-shadow: none;
                                height: 16px;
                                padding: 0px 8px;
                                margin-left: 95px;

                            }

                            &:hover {
                                background: rgba(63, 37, 21, 0.75);
                                border: 1px solid #A56B43;
                                border-radius: 8px;
                            }

                            &:active {
                                background: rgba(36, 20, 10, 0.75);
                                border: 1px solid #A56B43;
                                border-radius: 8px;
                            }
                        }
                    }

                    .discord-box {
                        margin-top: 34px;
                        display: flex;
                        //height: 48px;

                        .discord-btn {
                            padding-top: 6px;
                            padding-bottom: 6px;
                            padding-left: 24px;
                            padding-right: 24px;
                            background: rgba(63, 37, 21, 0.75);
                            border: 1px solid #A56B43;
                            border-radius: 8px;
                            display: flex;

                            margin-left: auto;
                            margin-right: auto;

                            text-decoration: none;

                            .icon {
                                display: flex;
                                align-items: center;

                                .discord-icon {
                                    width: 31px;
                                    height: 24px;
                                    background-image: url('./assets/images/discord-icon.svg');
                                }
                            }

                            .text {
                                padding-left: 10px;
                                display: flex;
                                font-family: "Open Sans";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                text-transform: uppercase;
                                color: #FFFFFF;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                                flex-wrap: nowrap;

                                span {
                                    padding-left: 10px;
                                    display: flex;
                                    font-family: "Open Sans";
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 16px;
                                    text-transform: uppercase;
                                    color: #FFFFFF;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: flex-start;
                                    flex-wrap: nowrap;
                                }

                                .text-bold {
                                    font-weight: 700;
                                }
                            }

                            &:hover {
                                background: #3F2515;
                                border: 1px solid #A56B43;
                            }

                            &:active {
                                background: rgba(36, 20, 10, 0.75);
                                border: 1px solid #A56B43;
                            }
                        }
                    }
                }
            }


        }

        &.open {
            .header-mob-view {
                display: flex;
            }

            @media only screen and (min-width: 992px) {
                .header-mob-view {
                    display: none;
                }
            }
        }
    }
}
.section-header-box {
  .section-header {
    font-family: "Chelsy", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;

    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.45);
  }

  @media only screen and (max-width: 991px) {
    .section-header {
      font-size: 48px;
      line-height: 48px;
    }
  }
}
